




















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})

export default class OverflowToolTip extends Vue {
  @Prop({ type: String, default: '' })
  private content?: string;

  @Prop({ type: String, default: 'bottom' })
  private placement?: string;

  @Prop({ type: String, default: '_overflow-tooltip-g_' })
  private toolTipClass?: string;

  private overflow = false

  mounted() {
    const isWidthOverflow: boolean = this.$el.clientWidth < this.$el.scrollWidth;
    // 为什么要再减去5呢？暂时不知会有什么影响。
    const isHeightOverflow: boolean = this.$el.scrollHeight - this.$el.clientHeight > 2;
    // const isHeightOverflow = this.$el.scrollHeight > this.$el.clientHeight;

    this.overflow = isWidthOverflow || isHeightOverflow;
  }
}
